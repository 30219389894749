import { useContext, useEffect, useState } from "react";
import { Menu, Modal } from "antd";
import {
  AlertOutlined,
  BankOutlined,
  BarChartOutlined,
  CalendarOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FlagOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  KeyOutlined,
  LockOutlined,
  NotificationOutlined,
  PictureOutlined,
  PieChartOutlined,
  PoweroffOutlined,
  ReadOutlined,
  RobotOutlined,
  ShareAltOutlined,
  ShopOutlined,
  SolutionOutlined,
  TableOutlined,
  TagsOutlined,
  UnorderedListOutlined,
  UserOutlined,
  VideoCameraOutlined,
  WarningOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/lib/menu/hooks/useItems";

import { NavLink, useLocation } from "react-router-dom";
import { checkPermission } from "~/utils/helpers";
import { GlobalContext } from "~/context/global.context";
import { IUserRole, PermissionLabel } from "~/model/enum";

export default function AppMenuItems({ collapsed }: { collapsed: boolean }) {
  const location = useLocation();
  const { user, updateUser } = useContext(GlobalContext);

  const [defaultSelectedMenu, setDefaultSelectedMenu] =
    useState<Array<string> | null>(null);
  const [defaultSelectedSubMenu, setDefaultSelectedSubMenu] =
    useState<Array<string> | null>(null);

  const getDefaultSelectedKeys = (type: "menu" | "submenu") => {
    const path = location.pathname;
    const pathArray = path.split("/");

    if (type === "menu") setDefaultSelectedMenu([pathArray[1]]);
    else setDefaultSelectedSubMenu([`${pathArray[1]}-${pathArray[2]}`]);
  };

  const handleLogout = () => {
    Modal.confirm({
      title: "Are you sure you want to logout?",
      onOk: () => {
        updateUser(true);
        window.location.href = "/";
      },
    });
  };

  useEffect(() => {
    getDefaultSelectedKeys("menu");
    getDefaultSelectedKeys("submenu");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!user) return null;

  const menuItems = [
    {
      key: "content-sources",
      title: "Content Sources",
      link: "/content-sources",
      icon: <ShareAltOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Sources),
      submenus: [
        {
          key: "content-sources-crawling",
          title: "Crawling",
          link: "/content-sources/crawling",
          icon: <FileSearchOutlined />,
          disabled: false,
        },
        {
          key: "content-sources-parsing",
          title: "Parsing",
          link: "/content-sources/parsing",
          icon: <FilePdfOutlined />,
        },
        {
          key: "content-sources-parsing-from-doi",
          title: "Parsing (from DOI)",
          link: "/content-sources/parsing-from-doi",
          icon: <LinkOutlined />,
        },
      ],
    },
    {
      key: "content-management",
      title: "Content Management",
      link: "/content-management",
      icon: <TableOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Content),
      submenus: [
        {
          key: "content-management-article",
          title: "Articles",
          link: "/content-management/article",
          icon: <FileTextOutlined />,
        },
        {
          key: "content-management-nectar",
          title: "Nectars",
          link: "/content-management/nectar",
          icon: <RobotOutlined />,
        },
        {
          key: "content-management-video",
          title: "Videos",
          link: "/content-management/video",
          icon: <VideoCameraOutlined />,
        },
        {
          key: "content-management-infographic",
          title: "Infographics",
          link: "/content-management/infographic/",
          icon: <PictureOutlined />,
        },
        {
          key: "content-management-playlist",
          title: "Playlists",
          link: "/content-management/playlist",
          icon: <UnorderedListOutlined />,
        },
        {
          key: "content-management-quizz",
          title: "Quizz",
          link: "/content-management/quizz",
          icon: <AlertOutlined />,
        },
      ],
    },
    {
      key: "reference-data",
      title: "Reference Data",
      link: "/reference-data",
      icon: <FolderOpenOutlined />,
      disabled: !checkPermission(user, PermissionLabel.Referential),
      submenus: [
        {
          key: "reference-data-company",
          title: "Companies",
          link: "/reference-data/company",
          icon: <ShopOutlined />,
        },
        {
          key: "reference-data-congress",
          title: "Congresses",
          link: "/reference-data/congress",
          icon: <CalendarOutlined />,
        },
        {
          key: "reference-data-tag",
          title: "Medical specialties",
          link: "/reference-data/tag",
          icon: <TagsOutlined />,
        },
        {
          key: "reference-data-journal",
          title: "Journals",
          link: "/reference-data/journal",
          icon: <ReadOutlined />,
        },
        {
          key: "reference-data-profession",
          title: "Professions",
          link: "/reference-data/profession",
          icon: <SolutionOutlined />,
          disabled: true,
        },
      ],
    },
    {
      key: "analytics",
      title: "Analytics",
      link: "/analytics",
      disabled: false,
      icon: <BarChartOutlined />,
      submenus: [
        {
          key: "analytics-performance-metrics",
          title: "Performance Metrics",
          link: "/analytics/performance-metrics/users",
          icon: <PieChartOutlined />,
        },
        {
          key: "analytics-content-monitoring",
          title: "Content Monitoring",
          link: "/analytics/content-monitoring",
          icon: <DashboardOutlined />,
        },
      ],
    },
    {
      key: "sensitive-data",
      title: "Sensitive Data",
      link: "/sensitive-data",
      icon: <WarningOutlined />,
      disabled: !checkPermission(user, PermissionLabel.SensitiveData),
      submenus: [
        {
          key: "sensitive-data-organisations",
          title: "Organisations",
          link: "/sensitive-data/organisations",
          icon: <BankOutlined />,
        },
        {
          key: "sensitive-data-rooms",
          title: "Rooms",
          link: "/sensitive-data/rooms",
          icon: <HomeOutlined />,
        },
        {
          key: "sensitive-data-users",
          title: "Users",
          link: "/sensitive-data/users",
          icon: <UserOutlined />,
        },
        {
          key: "sensitive-data-notifications",
          title: "Notifications",
          link: "/sensitive-data/notifications",
          icon: <NotificationOutlined />,
        },
        {
          key: "sensitive-data-announcements",
          title: "Pop-up Announcements",
          link: "/sensitive-data/announcements",
          icon: <FlagOutlined />,
        },
        {
          key: "sensitive-data-short-links",
          title: "Short Links",
          link: "/sensitive-data/short-links",
          icon: <LinkOutlined />,
        },
        {
          key: "sensitive-data-features-manager",
          title: "Features Manager",
          link: "/sensitive-data/features-manager",
          icon: <DeploymentUnitOutlined />,
        },
      ],
    },
    {
      key: "super-admin",
      title: "Super Admin",
      link: "/super-admin",
      disabled: !user.roles.includes(IUserRole.SUPER_ADMIN),
      icon: <KeyOutlined />,
      submenus: [
        {
          key: "super-admin-roles-permissions",
          title: "Roles & Permissions",
          link: "/super-admin/roles-permissions",
          icon: <LockOutlined />,
        },
      ],
    },
  ];

  const menuData: ItemType[] = (
    user?.roles?.includes(IUserRole.SUPER_ADMIN)
      ? menuItems
      : menuItems.filter((el) => el.key !== "superAdmin")
  ).map((item) => ({
    label: item.title,
    key: item.key,
    icon: item.icon,
    disabled: item.disabled,
    children: item.submenus.map((submenu) => ({
      label: <NavLink to={submenu.link}>{submenu.title}</NavLink>,
      key: submenu.key,
      icon: submenu.icon,
    })),
  })) as ItemType[];

  menuData?.push({
    key: "logout",
    label: "Logout",
    icon: <PoweroffOutlined />,
    danger: true,
    className: "logout-button",
    onClick: handleLogout,
  } as ItemType);

  return (
    <Menu
      theme='dark'
      className='sider-menu-menu'
      mode='inline'
      onOpenChange={(openKeys) => setDefaultSelectedMenu(openKeys)}
      openKeys={(!collapsed && defaultSelectedMenu) || undefined}
      selectedKeys={defaultSelectedSubMenu || undefined}
      onSelect={({ selectedKeys }) => setDefaultSelectedSubMenu(selectedKeys)}
      items={menuData}
    />
  );
}
