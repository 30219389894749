import { ArrowRightOutlined, RocketOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "~/components/shared/BackButton";
import { Flex, Spacer } from "~/components/shared/global";
import { getParsingList, parseArticleFromDoi } from "~/services";
import { getRequestErrorMessage } from "~/utils/helpers";
import columns from "./columns";
import { CrawlingFromDoiDto } from "~/model/dto/crawling-dto";
import { IParsing } from "~/model/parser.model";

const TIMER_INTERVAL = 5_000;

export default function ParsingFromDoi() {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [form] = Form.useForm();
  const [taskList, setTaskList] = useState<IParsing[]>([]);
  const navigate = useNavigate();

  async function handleSubmit(values: CrawlingFromDoiDto) {
    try {
      const article = await parseArticleFromDoi(values);

      if (article?._id) {
        notification.info({
          key: `notification-${article._id}`,
          message: "The article has been parsed and exists in the database.",
          description: (
            <Button
              style={{ padding: 0 }}
              icon={<ArrowRightOutlined />}
              type='link'
              onClick={() => {
                navigate(`/content-management/article/${article._id}`);
                notification.close(`notification-${article._id}`);
              }}
            >
              {"See article"}
            </Button>
          ),
        });
      } else {
        notification.success({
          message: "Crawling task has been created.",
          description:
            "Please wait for a moment to get the result, it will be shown in the table below.",
        });
      }
    } catch (error) {
      notification.error({
        message: "An error has occured while parsing.",
        description: getRequestErrorMessage(error),
      });
    } finally {
      setTimeout(() => {
        fetchTaskList();
      }, 1000);
    }
  }

  async function fetchTaskList() {
    const { docs: parsings } = await getParsingList({
      limit: 50,
      offset: 0,
      parsingType: "doi",
      sortBy: "creationDate",
      sortOrder: "desc",
    });

    setTaskList(parsings);
  }

  useEffect(() => {
    fetchTaskList();

    intervalRef.current = setInterval(() => {
      fetchTaskList();
    }, TIMER_INTERVAL);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div className='basic-layout'>
      <BackButton />
      <Flex align='end' justify='space-between'>
        <h1>Parse publication from DOI</h1>
        <Form form={form} onFinish={handleSubmit} layout='vertical'>
          <Flex align='end' gap={16}>
            <Form.Item name='doi' required>
              <Input
                addonBefore='DOI'
                placeholder='https://doi.example.10.1109/5.771073'
                style={{ width: 400 }}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType='submit'
                type='primary'
                icon={<RocketOutlined />}
              >
                {"Crawl now"}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Flex>
      <Spacer />
      <Table
        dataSource={taskList.map((task) => ({ ...task, key: task.taskId }))}
        columns={columns()}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
}
