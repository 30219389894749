import { ExportOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { Link } from "react-router-dom";
import { ParsingStatusTag } from "~/components/parsing/ParsingStatusTag";
import { ParsingStatusEnum } from "~/model/enum";
import { IParsing } from "~/model/parser.model";

const columns = (): ColumnsType<IParsing> => [
  {
    title: "Status",
    dataIndex: ["meta", "status"],
    key: "taskStatus",
    render: (_, parsing) => <ParsingStatusTag parsing={parsing} />,
  },
  {
    title: "View parsing",
    dataIndex: "id",
    key: "id",
    width: 200,
    render: (parsingId: string) => (
      <Link to={`/content-sources/parsing/${parsingId}`}>
        <Button icon={<EyeOutlined />} type='link'>
          View parsing
        </Button>
      </Link>
    ),
  },
  {
    title: "DOI",
    dataIndex: "doi",
    key: "doi",
    render: (doi: string) => (
      <a
        href={`https://doi.org/${doi}`}
        target='_blank'
        rel='noreferrer'
        style={{ display: "flex", gap: 8, alignItems: "center" }}
      >
        {doi}
        <ExportOutlined />
      </a>
    ),
  },
  {
    title: "Article",
    dataIndex: ["article", "title"],
    key: "articleTitle",
    width: 600,
    render: (title: string, task) => {
      const isSuccess = task.meta.status === ParsingStatusEnum.SUCCESS;
      const articleId = task.article?._id;

      return isSuccess && articleId ? (
        <Link
          to={`/content-management/article/${articleId}`}
          style={{ fontWeight: 600 }}
        >
          {title}
        </Link>
      ) : (
        <span
          style={{
            opacity: task.meta.status === ParsingStatusEnum.PENDING ? 1 : 0.5,
          }}
        >
          {title || "N/A"}
        </span>
      );
    },
  },
  {
    title: "Creation Date",
    dataIndex: ["meta", "creationDate"],
    key: "taskCreatedAt",
    width: 200,
    sorter: (a, b) => moment(a.meta.creationDate).diff(b.meta.creationDate),
    render: (taskCreatedAt: string) =>
      moment(taskCreatedAt)
        .utcOffset("+02:00")
        .format("DD/MM/YYYY \\a\\t HH:mm"),
  },
];

export default columns;
