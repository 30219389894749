import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  RobotOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { ParsingStatusEnum } from "~/model/enum";
import { IParsing } from "~/model/parser.model";

type ParsingStatusTagProps = {
  parsing: IParsing;
  size?: "small" | "large";
};

export const ParsingStatusTag = ({
  parsing,
  size = "small",
}: ParsingStatusTagProps) => {
  const [color, setColor] = useState("orange");
  const [icon, setIcon] = useState(<SyncOutlined />);
  const [text, setText] = useState<string>("Pending");

  const taskError = parsing.result?.error?.message || undefined;

  const taskStatus = parsing.meta.status;

  useEffect(() => {
    if (taskStatus === ParsingStatusEnum.SUCCESS && parsing.article) {
      setColor("green");
      setIcon(<CheckOutlined />);
      setText("Success");
    } else if (!parsing.article && parsing.result?.summarized) {
      setColor("blue");
      setIcon(<RobotOutlined />);
      setText("Summarized");
    } else if (taskStatus === ParsingStatusEnum.DUPLICATE) {
      setColor("volcano");
      setIcon(<CopyOutlined />);
      setText("Duplicate");
    } else if (taskStatus === ParsingStatusEnum.FAILURE) {
      setColor("red");
      setIcon(<CloseOutlined />);
      setText("Failure");
    } else {
      setColor("orange");
      setIcon(<SyncOutlined spin />);
      setText("Pending");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsing]);

  return (
    <Tooltip title={`ERROR: ${taskError}`}>
      <Tag
        color={color}
        icon={icon}
        style={
          size === "small"
            ? {
                textTransform: "capitalize",
              }
            : {
                padding: "6px 16px",
                fontWeight: 600,
                margin: 0,
                textTransform: "uppercase",
              }
        }
      >
        {text}
      </Tag>
    </Tooltip>
  );
};
