import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  notification,
} from "antd";

import {
  deleteParsing,
  deleteParsingBatch,
  getParsingList,
  postRetryAllParsing,
  postRetryParsing,
} from "~/services";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Flex } from "~/components/shared/global";
import { ArrowLeftOutlined, SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import { IParsing } from "~/model/parser.model";
import columns from "./columns";
import { IQueryParsing } from "~/model/query.model";

const FILTERS_STATUS = ["Pending", "Success", "Duplicate", "Failure"];

export default function ParsingList(pageProps: { batchMode?: boolean }) {
  const navigate = useNavigate();
  const params = useParams<{ batchName: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [innerParsingList, setInnerParsingList] = useState<IParsing[]>([]);
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedParsingRows, setSelectedParsingRows] = useState<IParsing[]>(
    []
  );

  const fetchParsings = async () => {
    setIsLoading(true);

    const { docs: parsingList, meta } = await getParsingList({
      limit: Number(searchParams.get("limit")) || 50,
      offset: Number(searchParams.get("offset")) || 0,
      status:
        (searchParams.get("status") as IQueryParsing["status"]) || undefined,
      sortOrder:
        (searchParams.get("sortOrder") as IQueryParsing["sortOrder"]) || "desc",
      sortBy:
        (searchParams.get("sortBy") as IQueryParsing["sortBy"]) ||
        "creationDate",
      batchName: params.batchName || undefined,
    });

    if (parsingList.length) {
      setInnerParsingList(parsingList);
    }

    if (meta.total) {
      setCount(meta.total);
    }
    setIsLoading(false);
  };

  const handleCreateNewParsing = () => {
    navigate("/content-sources/parsing/create/");
  };

  const handlePaginationChange = async (page: number, pageSize?: number) => {
    if (pageSize) {
      setIsLoading(true);
      setPage(page);
      const offset = (page - 1) * pageSize < 0 ? 0 : (page - 1) * pageSize;
      navigate({
        search: `?offset=${offset}&limit=${pageSize}`,
      });
      setIsLoading(false);
    }
  };

  const handleFilterChange = (status?: IParsing["meta"]["status"]) => {
    selectedParsingRows.length && setSelectedParsingRows([]);
    if (status) searchParams.set("status", status);
    else searchParams.delete("status");
    setSearchParams(searchParams);
  };

  const handleSortChange = (value?: "asc" | "desc") => {
    searchParams.set("sortOrder", value || "desc");
    searchParams.set("sortBy", "creationDate");
    setSearchParams(searchParams);
  };

  function filterInnerParsing(innerParsingList: IParsing[]): IParsing[] {
    const uniqueBatchNames: { [batchName: string]: boolean } = {};

    return innerParsingList.filter((parsing) => {
      if (!parsing?.batchName) return true;
      if (parsing?.batchName && !(parsing.batchName in uniqueBatchNames)) {
        uniqueBatchNames[parsing?.batchName] = true;
        return true;
      }
      return false;
    });
  }

  const deleteSelectedParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        await Promise.all(
          selectedParsingRows.map(async (parsing) => {
            if (parsing.batchName && !params.batchName)
              await deleteParsingBatch(parsing.batchName);
            else await deleteParsing(parsing.id);
          })
        );
        notification.success({
          message: "Parsings deleted successfully",
        });
        setSelectedParsingRows([]);
        fetchParsings();
      },
    });
  };

  const handleRetryParsings = async () => {
    Modal.confirm({
      title: "Are you sure you want to retry the selected parsings?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await Promise.all(
            selectedParsingRows.map(async (parsing) => {
              await postRetryParsing(parsing.taskId);
            })
          );
          notification.success({
            message: "Parsings re-tried successfully",
          });

          setSelectedParsingRows([]);
          fetchParsings();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            notification.error({
              message: "An error occurred while retrying parsing",
              description: error?.response?.data.message || "Unknown error",
            });
          }
        }
      },
    });
  };

  const handleRetryAllParsings = async () => {
    try {
      await postRetryAllParsing();
      notification.success({
        message: "All parsings re-tried successfully",
      });
    } catch (error) {
      if (axios.isAxiosError(error))
        notification.error({
          message: "An error occurred while retrying parsing",
          description: error?.response?.data.message || "Unknown error",
        });
    }
  };

  useEffect(() => {
    fetchParsings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Layout
      style={{
        overflow: "hidden",
        boxSizing: "border-box",
        height: "calc(100vh - 66px)",
      }}
    >
      <Layout.Content
        style={{
          padding: "20px 50px 50px",
          boxSizing: "border-box",
        }}
      >
        {pageProps.batchMode ? (
          <Button
            icon={<ArrowLeftOutlined />}
            type='link'
            style={{ padding: 0 }}
            onClick={() => navigate(-1)}
          >
            {"Back to parsings list"}
          </Button>
        ) : (
          <Button />
        )}
        <Row
          justify='space-between'
          align='middle'
          style={{ margin: "20px 0" }}
        >
          <h1 style={{ fontWeight: 800, fontSize: "30px", margin: 0 }}>
            {pageProps.batchMode
              ? `Batch parsing "${params.batchName}"`
              : "Parsing list"}
          </h1>

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Flex gap={8} align='center' style={{ width: "fit-content" }}>
              {!selectedParsingRows.length ? (
                <Button
                  icon={<SyncOutlined />}
                  disabled={
                    !innerParsingList.some((el) => el.meta.status === "failure")
                  }
                  onClick={handleRetryAllParsings}
                >
                  {"Retry All Failed"}
                </Button>
              ) : (
                <Button
                  icon={<SyncOutlined />}
                  disabled={
                    !selectedParsingRows.some(
                      (el) => el.meta.status === "failure"
                    )
                  }
                  onClick={handleRetryParsings}
                >
                  {"Retry selected"}
                </Button>
              )}

              {selectedParsingRows.length > 0 && (
                <Button
                  type='primary'
                  onClick={deleteSelectedParsings}
                  disabled={!selectedParsingRows.length}
                  danger
                >
                  Delete selected
                </Button>
              )}
              <Divider type='vertical' />
            </Flex>
            {!pageProps.batchMode && (
              <Button
                type='primary'
                onClick={handleCreateNewParsing}
                style={{ marginRight: 10 }}
              >
                New parsing
              </Button>
            )}

            <Divider type='vertical' />
            <div style={{ color: "#000", fontWeight: 500 }}>
              Filter by status:
            </div>
            <Select
              placeholder='Status'
              style={{ width: 120 }}
              options={FILTERS_STATUS.map((status) => ({
                label: status,
                value: status.toLocaleLowerCase(),
              }))}
              onChange={handleFilterChange}
              allowClear
            />
            <Divider type='vertical' />
            <div style={{ color: "#000", fontWeight: 500 }}>
              Sort by creation date
            </div>
            <Select
              placeholder='Asc / Desc'
              style={{ width: 120 }}
              defaultValue={"desc"}
              options={[
                {
                  label: "Ascending",
                  value: "asc",
                },
                {
                  label: "Descending",
                  value: "desc",
                },
              ]}
              onChange={handleSortChange}
            />
          </div>
        </Row>
        <div className='site-layout-content'>
          <Spin spinning={isLoading} tip='Loading...'>
            <Table
              size='small'
              columns={columns}
              dataSource={(params.batchName
                ? innerParsingList
                : filterInnerParsing(innerParsingList)
              ).map((parsing) => ({ ...parsing, key: parsing.id }))}
              rowSelection={{
                selectedRowKeys: selectedParsingRows.map(
                  (parsing) => parsing.id
                ),
                onChange: (_: React.Key[], selectedRows) => {
                  setSelectedParsingRows(selectedRows);
                },
                getCheckboxProps: (parsing) => ({
                  name: parsing.batchName,
                }),
              }}
              pagination={false}
              scroll={{
                x: "max-infographic",
                y: "calc(100vh - 330px)",
              }}
              sticky
            />
          </Spin>
          <Pagination
            style={{ marginTop: "10px" }}
            onChange={handlePaginationChange}
            total={count}
            current={page}
            defaultCurrent={1}
            defaultPageSize={50}
            pageSizeOptions={["50", "75", "100"]}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
}
